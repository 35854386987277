.main-home {
 width: 98vw;
 height: max-content;
}
.home {
 display: flex;
 width: 100%;
 height: 100vh;
}
.title {
 width: 50%;
 display: flex;
 align-items: center;
 justify-content: center;
}
.title div {
 text-align: right;
 /* margin: 0 1.5em; */
}
@media only screen and (max-width: 1024px) {
    .title {
    width: 100%;
    }
    .big-background h1 {
        overflow: hidden;
        width: 115%;
    height: 90%;

    }
    .big-background {
    /* overflow: visible; */
    position: absolute;
    width: 100%;
    height: 200%;

    
    
    }
    /* lower right */
    .home-social .circle:nth-child(4) {
    bottom: -40%;
    right: -10%;
    background-color: var(--font-color);
    }
    /* lower :left */
    .home-social .circle:nth-child(3) {
    bottom: -20%;
    right: -10%;
    }
    .home-social h2{
        z-index: 3;
        font-size: 3em;
        color: rgb(157, 157, 157);
    }
        .home-social{
            overflow: hidden;
        }
}
@media only screen and (min-width: 1024px) {
        .home-social h2{
    font-size: 4em;
            
            color: var(--font-faded-color);
        }
    .big-background {
    width: 50%;
    
    }
    
    /* lower right */
    .home-social .circle:nth-child(4) {
    bottom: -20%;
    right: 4%;
    background-color: var(--font-color);
    }
    /* lower :left */
    .home-social .circle:nth-child(3) {
    bottom: 1%;
    right: 10%;
    }
    .home-social{
        overflow: auto;
    }
}
.big-background {

 display: flex;
 /* position: relative; */

}
.big-background h1 {
 font-size: 75em;
 position: absolute;


 top: -20vw;
 left: -15vw;
 margin: 0;
 line-height: 0.6em;

 color: var(--font-faded-color);
}

.home-social {
 display: flex;
 width: 100%;
 justify-content: center;
 align-items: center;
 flex-direction: column;
 position: relative;
 
}
.home-social h2 {
 position: absolute;
 bottom: 20%;
 /* color: var(--font-faded-color); */
}
.home-social .social-cont {
 width: 100%;
 height: 90%;
 display: flex;
 justify-content: center;
 align-items: center;
}
.social-cont a {
 margin: 0 0.6em;
 border-radius: 16px;
 transition: background-color 0.2s ease-in-out;
}
.social-cont a svg {
 transition: fill 0.25s ease-in-out;
}
.social-cont h4 {
 font-weight: 600;
}
.social-cont a:hover {
 background-color: var(--font-color);
 cursor: pointer;
}
.social-cont a:hover svg {
 fill: var(--background-color);
}
a.scroll:nth-child(1) {
 transition: transform 100ms;
}
a.scroll:nth-child(2) {
 transition: transform 145ms;
}
a.scroll:nth-child(3) {
 transition: transform 185ms;
}
a.scroll:nth-child(4) {
 transition: transform 225ms;
}
a.scroll:nth-child(5) {
 transition: transform 245ms;
}
.home-social .circle {
 position: absolute;
 --size: 20em;
 width: var(--size);
 height: var(--size);
 border-radius: 100vw;
 background-color: var(--font-faded-color);
 transition: transform 0.2s ease-out;
}
/* upper right */
